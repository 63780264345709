<script>
export default {
  name: "ConfidentialityView"
}
</script>

<template>
  <div class="container">
    <div class="titre">
      <h2 class="elementor-heading-title elementor-size-default">Politique de Confidentialité et de Protection des
        Données Personnelles</h2>
    </div>
    <div class="text">
      <p>En utilisant le site https://www.neofutur.com, vous reconnaissez avoir pris connaissance de notre Politique
        de Confidentialité et de Protection des Données Personnelles et consentez à ce que nous collections et
        utilisions ces données pour des usages internes. <br>Aucune information personnelle de l’utilisateur du
        site www.neofutur.com n’est échangée, transférée, cédée ou vendue sur un support quelconque à des tiers à
        l’insu de l’utilisateur.</p>
      <p><strong>Article 1 : Responsable du traitement </strong><br><br>Le responsable du traitement des données est
        la société NEOFUTUR. <br>Les coordonnées de contact sont les suivantes : <br>Adresse postale : 21, avenue
        Saint-Fiacre – 78100 Saint-Germain-en-Laye <br>Email : info@neofutur.com</p>
      <p><strong>Article 2 : Données personnelles recueillies</strong></p>
      <p>Lors d’une inscription sur notre formulaire contact, nous recueillons les renseignements personnels que vous
        nous fournissez, et notamment : votre nom, <br>votre prénom, <br>votre téléphone, <br>le nom de votre société,
        association ou structure <br>votre adresse e-mail</p>
      <p>Certaines de ces données doivent être obligatoirement renseignées pour nous permettre de gérer au mieux votre
        demande.</p>
      <p>Lors de l’accès à la partie authentifiée de notre site, vous avez accès à vos devis, commandes et
        interventions techniques effectués. Ces données sont conservées tout au long de la relation client/fournisseur
        qui nous lie. <br>S’agissant de vos données bancaires, nous ne stockons aucune coordonnée bancaire.</p>
      <p><strong>Article 3 : Finalité de la collecte des données personnelles</strong></p>
      <p>En fonction de leur finalité, ces données sont collectées soit parce que vous acceptez de nous les
        communiquer, soit parce qu’elles sont nécessaires à l’exécution de nos contrats ou de nos prestations, soit
        également parce qu’elles répondent à une obligation légale. <br>Nous collectons des données pour les finalités
        suivantes :</p>
      <p><strong>3.1 Gestion des achats et facturation</strong></p>
      <p>Les données collectées à ce titre ont pour finalité la bonne gestion, l’exécution et la fourniture de vos
        commandes de produits et de services, ce qui inclut également la gestion de la facturation. Ces finalités ont
        pour fondement l’exécution du contrat de vente ou de fourniture de nos produits ou services.</p>
      <p><strong>3.2 Gestion de la relation client</strong></p>
      <p>Les données collectées à ce titre ont pour finalité le suivi de la relation client. Ces finalités ont pour
        fondement l’exécution du contrat de vente ou de fourniture de nos produits ou services.</p>
      <p><strong>3.3 Amélioration et personnalisation de nos services</strong></p>
      <p>Nous collectons des données dans une finalité d’améliorer et de personnaliser les services que nous vous
        proposons. <br>Ces traitements sont fondés sur votre consentement comme l’utilisation de données collectées
        via certains cookies.</p>
      <p><strong>3.4 Informations</strong></p>
      <p>Cette finalité a pour objet de vous adresser nos informations et nos offres concernant notre activité, nos
        produits et nos services. Cette finalité repose sur votre consentement, de sorte que vous pouvez choisir à
        tout moment de recevoir des offres de notre part par email ou de vous désinscrire.</p>
      <p><strong>3.5 Contact service client</strong></p>
      <p>La finalité de ce traitement est de nous permettre de répondre aux questions que vous nous adressez et qui
        peuvent porter notamment sur nos produits, nos services, l’exercice de vos droits ainsi que toute autre
        information. Il a pour fondement notre intérêt légitime de pouvoir répondre à vos questions.</p>
      <p><strong>Article 4 : Durée de conservation des données personnelles</strong></p>
      <p>Conformément à la législation et réglementation en vigueur, vos données sont conservées pour la durée
        strictement nécessaire aux finalités poursuivies telles que décrites ci-dessus.</p>
      <p>Ainsi, pour les finalités suivantes :</p>
      <p>Gestion des commandes, livraison, facturation, <br>Gestion de la relation client, <br>Informations et
        newsletters <br>Contact service client,</p>
      <p>La durée de conservation des données des clients est celle de la relation commerciale augmentée de 3 ans à
        des fins de prospection commerciale. <br>En outre, pour la finalité amélioration et personnalisation de nos
        services : la durée de conservation des cookies est de 13 mois maximum, et la durée de conservation des
        données des clients est celle de la relation commerciale augmentée de 3 ans à des fins de prospection
        commerciale.</p>
      <p>Enfin, pour satisfaire à nos obligations légales, par exemple dans le cadre de la constatation, l’exercice ou
        la défense de droits en justice ou bien pour répondre à des obligations légales notamment comptables, fiscales
        ou d’archivage, nous pouvons être amenés à conserver vos données plus longtemps que les durées mentionnées
        ci-dessous. Dans ce cas, nous réaliserons un archivage de vos données avec accès restreint au seul responsable
        du traitement.</p>
      <p><strong>Article 5: Destinataire des données collectées</strong></p>
      <p>Les données collectées sont réservées à un usage purement interne. Nous ne procédons à aucune cession, ni
        commercialisation de vos données. <br>Toutefois, pour fournir nos services, nous pouvons cependant être amenés
        à communiquer certaines de vos données à nos prestataires. En outre, les partenaires de nos sites internet
        peuvent également être amenés à collecter des données vous concernant et pour lesquels ils sont eux-mêmes
        responsables de traitement.</p>
      <p><strong>Article 6 : Exercice de vos droits</strong></p>
      <p>Conformément à la Loi Informatique et Libertés et la réglementation européenne sur la protection des données
        personnelles, vous disposez de droits sur les données vous concernant :</p>
      <p>Droit d’accès : Vous avez le droit de nous demander confirmation que vos données font bien l’objet d’un
        traitement et obtenir les informations relatives à ce traitement (finalités, destinataires etc…)</p>
      <p>Droit de rectification et d’effacement des données : Vous pouvez demander la rectification de vos données
        lorsqu’elles sont inexactes ou incomplètes. Vous pouvez également demander l’effacement de vos données sous
        réserve de nos obligations légales de conservation.</p>
      <p>Droit à la limitation du traitement : vous pouvez demander la limitation du traitement de vos Données, sous
        réserve du respect des obligations légales</p>
      <p>Droit d’opposition : vous pouvez vous opposer à tout moment au traitement de vos données, sauf en cas de
        motifs légitimes et impérieux de notre part. Vous pouvez également vous opposer au traitement fait à des fins
        de prospection.</p>
      <p>Droit à la portabilité : si les exigences légales sont remplies, vous pouvez demander à recevoir vos données
        dans un format structuré, couramment utilisé, lisible par machine et interopérable, et de les transmettre à un
        autre responsable du traitement sans que nous y fassions obstacle.</p>
      <p>Droit de ne pas faire l’objet d’une décision fondée sur un traitement automatisé : vous avez le droit de ne
        pas faire l’objet d’une décision fondée exclusivement sur un traitement automatisé produisant des effets
        juridiques vous concernant ou vous affectant, sauf lorsque cette décision est nécessaire à la conclusion ou à
        l’exécution d’un contrat, ou est autorisée légalement. Nous vous informons que nous ne prenons aucune décision
        automatisée</p>
      <p>Réclamations : vous avez le droit d’introduire une réclamation auprès d’une autorité de contrôle.</p>
      <p>Directives en cas de décès : vous avez le droit de donner des directives pour le sort de vos données
        personnelles en cas de décès.</p>
      <p>Vous trouverez ici une information concernant l’exercice de ces droits :
        https://www.cnil.fr/fr/comprendre-vos-droits <br>Pour exercer vos droits, il vous suffit de contacter le
        responsable du traitement :</p>
      <p>Soit par lettre recommandée avec demande d’avis de réception à l’adresse suivante : <br>Neofutur – 21, avenue
        Saint-Fiacre – 78100 Saint-Germain-en-Laye <br>Soit par courriel à l’adresse de contact suivante :
        info@neofutur.com</p>
      <p>Conformément à la réglementation en vigueur, votre demande doit être signée et accompagnée de la photocopie
        d’un titre d’identité portant votre signature et préciser l’adresse à laquelle doit vous parvenir la réponse.
        Une réponse vous sera alors adressée dans un délai d’1 mois maximum à compter de la réception de la
        demande.</p>
      <p><strong>Article 7 : Liens hypertexte et cookies</strong></p>
      <p>Le site www.neofutur.com peut contenir des liens hypertextes vers d’autres sites. Cependant, nous n’avons pas
        la possibilité de vérifier le contenu des sites ainsi visités, et nous n’assumons en conséquence aucune
        responsabilité de ce fait. <br>La navigation sur le site www.neofutur.com est susceptible de provoquer
        l’installation de cookie(s) sur l’ordinateur de l’utilisateur. Un cookie est un fichier de petite taille, qui
        ne permet pas l’identification de l’utilisateur, mais qui enregistre des informations relatives à la
        navigation d’un ordinateur sur un site. Les données ainsi obtenues visent à faciliter la navigation ultérieure
        sur le site, et ont également vocation à permettre diverses mesures de fréquentation. <br>Vous avez la
        possibilité d’accepter ou de refuser les cookies en modifiant les paramètres de votre navigateur. Cependant,
        vous risquez de perdre certaines fonctions interactives du site si les cookies sont désactivés.</p>
      <p><strong>Article 8 : Sécurité</strong></p>
      <p>Pour protéger vos données personnelles, nous prenons des précautions raisonnables et suivons les meilleures
        pratiques de l’industrie pour nous assurer qu’elles ne soient pas perdues, détournées, consultées, divulguées,
        modifiées ou détruites de manière inappropriée. <br>Notre site est mis à jour régulièrement et consulté
        quotidiennement, il fait l’objet d’une sécurité avancée. Si vos données devaient être compromises de quelques
        manières que ce soit, nous sommes tenus d’en informer la CNIL dans les 72h. Vous serez également tenu au
        courant de cet éventuel incident et des mesures correctives mise en œuvre.</p>
      <p><strong>Article 9 : Modifications apportées à la présente politique de confidentialité</strong></p>
      <p>Nous nous réservons le droit de modifier la présente politique de confidentialité à tout moment. Nous vous
        invitons donc à la consulter fréquemment. Les changements et les clarifications prendront effet immédiatement
        après leur publication sur le site web. Si nous apportons des changements au contenu de cette politique, nous
        vous aviserons de sa mise à jour.</p>
      <p><strong>Article 10 : Questions et coordonnées</strong></p>
      <p>Pour toutes questions relatives à la présente politique de confidentialité, nous vous invitons à contacter la
        société NEOFUTUR soit par email info@neofutur.com ou par courrier à l’adresse suivante : Neofutur – 21, avenue
        Saint-Fiacre – 78100 Saint-Germain-en-Laye</p>
    </div>
  </div>

</template>

<style scoped lang="scss">
  .container {
    padding: 80px 0;
    font-family: "Roboto Thin", sans-serif;
    .titre {
      text-align: center;
      h2 {
        color: #12426E;
        padding: 20px 0;
      }
    }

    .text {
      width: 80%;
      margin: auto;
      p {
        margin: 20px 0;
      }
      strong {
        color: #12426E;
      }
    }
  }
</style>